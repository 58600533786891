import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useRouter } from 'next/navigation';
import { datadogLogs } from '@datadog/browser-logs';
import { jwtDecode } from 'jwt-decode';
import toast from 'react-hot-toast';
import { Address as AccountAddress } from 'shared/types/account/Address';
import { Cart, LineItem } from 'shared/types/cart';
import countries from 'shared/types/countries.json';
import { Category } from 'shared/types/product';
import { mutate } from 'swr';
import Button from 'components/commercetools-ui/atoms/button';
import Dropdown from 'components/commercetools-ui/atoms/dropdown';
import Secure from 'components/commercetools-ui/organisms/checkout/components/secure';
import PaymentMethods from 'components/commercetools-ui/organisms/order-payment-section/components/payment-methods';
import OrderSummary from 'components/commercetools-ui/organisms/order-summary';
import Alert from 'components/padi-ui/alert';
import { PURCHASE } from 'helpers/constants/events';
import { useFormat } from 'helpers/hooks/useFormat';
import useGetUserInfo from 'helpers/hooks/useGetUserInfo';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { getLocalStorage, setLocalStorage } from 'helpers/utils/localStorage';
import mapCosts from 'helpers/utils/mapCosts';
import { desktop } from 'helpers/utils/screensizes';
import { getLocaleByZone } from 'project.config';
import { sdk } from 'sdk';
import { useAccount, useCart, useProduct } from 'frontastic';
import { getZoneKeyByCountryCode } from 'frontastic/hooks';
import usePurchase from './hooks/usePurchase';
import { useTrackClick } from './hooks/useTrack';

export interface State {
  stateId: number;
  name: string;
  code: string;
}

export interface SSOToken {
  given_name: string;
  family_name: string;
  email: string;
  birthdate: string;
  sub: string;
  'custom:affiliate_id': string;
}

const AddressForm: React.FC = () => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatMessagePadi } = useFormat({ name: 'padi' });
  const { locale } = useParams();
  const router = useRouter();
  const { createStripeCheckoutSession, updateUserCartAddress, createPaymentForPaypal, createOrderForPaypal } =
    usePurchase();
  const { getProjectConfig, account } = useAccount();
  const { data: cart } = useCart();
  const { getUserEntitlments } = useGetUserInfo();
  const { trackClick } = useTrackClick();
  const [isLoading, setIsLoading] = useState(true);
  const [isValidating, setIsValidating] = useState(true);
  const [isLoadingPaypal, setIsLoadingPaypal] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoadingOrder, setIsLoadingOrder] = useState(true);
  const [isLocaleChanged, setIsLocaleChanged] = useState(false);
  const [isSubscriptionInCart, setIsSubscriptionInCart] = useState(false);
  const [hasShippableItem, setHasShippableItem] = useState(false);
  const hasShippableItemRef = useRef(hasShippableItem);
  const [nonsellableMessage, setNonsellableMessage] = useState('');
  const [states, setStates] = useState<State[]>([]);
  const [isAlreadyClubMember, setIsAlreadyClubMember] = useState(true);

  let retryTimeout: any = null;
  const [addressFormData, setAddressFormData] = useState<AccountAddress>({});
  const addressFormDataRef = useRef(addressFormData);
  const { getProduct } = useProduct();

  const getSubscriptionProductTypeId = useCallback(async () => {
    const response: { [key: string]: any } = await sdk.callAction({
      actionName: 'product/queryProductTypeByKey',
      query: { key: 'subscription' },
    });
    if (response) {
      const id = response?.data?.id as string;
      return id;
    }
  }, []);

  const checkIfSubscriptionInCart = useCallback(async (lineItems: LineItem[]) => {
    const subscriptionId = await getSubscriptionProductTypeId();
    return lineItems?.some((i) => i?.productType?.id === subscriptionId);
  }, []);

  useEffect(() => {
    if (!cart || !cart?.billingAddress || !firstLoad) return;
    if (cart && (!cart.lineItems || cart.lineItems.length === 0)) {
      router.push('/');
      return;
    }

    let userCountry = getLocalStorage('padiUserCountry');
    const userLocale = getLocalStorage('padiUserLocale');
    datadogLogs.logger.info('Checkout page - Load', {
      data: {
        email: account?.email,
        cart,
        locale,
        userCountry,
        userLocale,
      },
    });

    const validateClubUserAndLoadPaypal = async () => {
      // Check if a club member is checking out another subscription
      const isCheckoutSubscription = await checkIfSubscriptionInCart(cart?.lineItems as LineItem[]);
      setIsSubscriptionInCart(isCheckoutSubscription);
      let isAlreadyMember = false;
      if (isCheckoutSubscription) {
        const idToken = window.localStorage.getItem('idToken');
        const user = jwtDecode<SSOToken>(idToken || '');
        const userEntitlements = await getUserEntitlments(user?.sub);
        if (userEntitlements?.customerStatus?.entitlementStatus === 'ACTIVE') {
          isAlreadyMember = true;
        }
      }
      if (!isAlreadyMember) {
        setIsAlreadyClubMember(false);
      }

      //Need to load Paypal or not
      if (isAlreadyMember || !cart.sum?.centAmount || cart.sum?.centAmount === 0) {
        setIsLoadingPaypal(false);
      } else {
        loadPaypal(cart.sum?.currencyCode);
      }
    };
    validateClubUserAndLoadPaypal();

    if (!userCountry) {
      datadogLogs.logger.info('Checkout page - No saved user country', {
        data: {
          email: account?.email,
          cart,
          userCountry,
          locale,
          userLocale,
        },
      });
      setLocalStorage('padiUserLocale', 'en-us');
      setLocalStorage('padiUserZone', 'zone-1');
      setLocalStorage('padiUserCountry', 'us');
      userCountry = 'us';
    }
    let billingAddress = cart?.billingAddress;
    userCountry = userCountry.toUpperCase();
    if (userCountry && userCountry !== billingAddress?.country) {
      billingAddress = {
        ...cart.billingAddress,
        country: userCountry,
        state: undefined,
      };
      if (billingAddress.additionalAddressInfo) {
        const additionalAddressInfo = JSON.parse(billingAddress.additionalAddressInfo);
        const country = countries.find((c) => c.twoLetterISORegionName === billingAddress.country);
        additionalAddressInfo.taxAddress = {
          countryName: country?.englishName,
          countryId: country?.id,
        };
        billingAddress.additionalAddressInfo = JSON.stringify(additionalAddressInfo);
      }
    }
    const loadStateList = async (address: AccountAddress) => {
      setIsLoading(true);
      const billingAddress = address;
      if (address.state && address.state.trim().length === 2) {
        billingAddress.state = `${address.country}-${address.state.trim()}`;
      }
      datadogLogs.logger.info('Checkout page - Address Data', {
        data: {
          email: account?.email,
          cart,
          addressFormData: billingAddress,
          userCountry: getLocalStorage('padiUserCountry'),
        },
      });
      setAddressFormData(billingAddress);
      await getStates(billingAddress.country, billingAddress.state);
      setIsLoading(false);
    };
    if (!isConfirmed || firstLoad) {
      setFirstLoad(false);
      loadStateList(billingAddress);
    }

    const validate = async () => {
      setIsValidating(true);
      const isValidCountry = await validateCountry(billingAddress.country || '');
      if (!isValidCountry) {
        setIsConfirmed(false);
        setIsValidating(false);
        return;
      }

      setIsValidating(false);
    };
    validate();
  }, [cart]);

  const handleCountryChange = async (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setIsLocaleChanged(true);
    setIsConfirmed(false);
    const countryCode = e?.target.value;
    const isValidCountry = await validateCountry(countryCode);
    datadogLogs.logger.info('Checkout page - Change country', {
      data: {
        email: account?.email,
        cart,
        isValidCountry,
        isLocaleChanged,
        countryCode,
        addressFormData,
      },
    });
    if (isValidCountry) {
      setIsLoading(true);
      await getStates(countryCode);
      setIsLoading(false);
    } else {
      const billingAddress = {
        ...cart?.billingAddress,
        ...addressFormData,
        country: countryCode,
        state: '',
      };
      setAddressFormData(billingAddress);
      setStates([]);
    }
  };

  const getCategoryIdBySlug = async (slug: string) => {
    if (!slug) return null;
    const response: { [key: string]: any } = await sdk.callAction({
      actionName: '/product/queryCategories',
      query: { whereQuery: `(slug(en="${slug}"))` },
    });
    if (response) {
      const category = response?.data?.items[0];
      return category;
    }
    return null;
  };

  const handleStateChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setIsConfirmed(false);
    const billingAddress = {
      ...addressFormData,
      state: e.target.value,
    };
    setAddressFormData(billingAddress);
  };

  const getStates = async (countryCode?: string, state?: string) => {
    const countryId = countries.find((c) => c.twoLetterISORegionName === countryCode)?.id?.toString();
    const globalEndpoint = await getProjectConfig('EXTENSION_PADI_APIS_LEARNING_GLOBAL_ENDPOINT');
    const idToken = window.localStorage.getItem('idToken');
    const response = await fetch(`${globalEndpoint.setting}/countries/${countryId}/states`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      const sortedStates = data.items.sort((a: any, b: any) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      const countryState =
        sortedStates.length === 0
          ? ''
          : state && sortedStates.find((s: any) => s.code === state)
          ? state
          : sortedStates[0]?.code;
      const billingAddress = {
        ...cart?.billingAddress,
        ...addressFormData,
        country: countryCode,
        state: countryState,
      };
      if (billingAddress.additionalAddressInfo) {
        const additionalAddressInfo = JSON.parse(billingAddress.additionalAddressInfo);
        const country = countries.find((c) => c.twoLetterISORegionName === billingAddress.country);
        additionalAddressInfo.taxAddress = {
          countryName: country?.englishName,
          countryId: country?.id,
        };
        billingAddress.additionalAddressInfo = JSON.stringify(additionalAddressInfo);
      }
      setAddressFormData(billingAddress);
      setStates(sortedStates);
    }
  };

  const loadPaypal = async (currencyCode?: string) => {
    const clientId = await getPaypalClientId(currencyCode);
    if (!document.getElementById('paypalsc')) {
      const paypalScript = document.createElement('script');
      paypalScript.setAttribute('src', 'https://www.paypalobjects.com/api/checkout.js');
      paypalScript.setAttribute('id', 'paypalsc');
      document.body.appendChild(paypalScript);
      paypalScript.onload = () => {
        renderPaypalButton(clientId);
      };
    } else {
      const elementIsReady = document.getElementById('paypal-button');
      if (!elementIsReady || !elementIsReady.innerHTML) {
        renderPaypalButton(clientId);
      }
    }
    setIsLoadingPaypal(false);
  };

  const getPaypalClientId = async (currencyCode?: string) => {
    const eCommUrl = await getProjectConfig('EXTENSION_ECOMM_BASE_URL');
    const paypalSetting = await fetch(`${eCommUrl.setting}/paypal/clientId/currencyCode/${currencyCode}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const paypalSettingData = await paypalSetting.json();
    return paypalSettingData.clientId;
  };

  const updateAddress = useCallback(
    async (billingAddress: AccountAddress) => {
      const country = countries.find((c) => c.twoLetterISORegionName === billingAddress.country);
      const validBillingAddress =
        country && country.mappingCode ? { ...billingAddress, country: country.mappingCode } : billingAddress;
      const address = {
        firstName: account?.firstName,
        lastName: account?.lastName,
        ...validBillingAddress,
      };
      const token = window.localStorage.getItem('idToken');
      const user = jwtDecode<SSOToken>(token || '');
      const affiliateId = user['custom:affiliate_id'] || '';
      const storeNumber = getLocalStorage('affiliateDiveShop') || '';

      return await updateUserCartAddress(address, affiliateId, storeNumber, country);
    },
    [cart],
  );

  const generatePaypalBill = async () => {
    const itemsPaypal: any[] = [];
    const cartRefresh = await mutate('/action/cart/getCart');
    const updatedCart = (cartRefresh.isError ? {} : cartRefresh.data) as Cart;
    updatedCart?.lineItems?.forEach((item) => {
      itemsPaypal.push({
        name: item.name,
        currency: updatedCart?.sum?.currencyCode,
        quantity: item.count,
        price: ((item.price?.centAmount ?? 0) / 100).toFixed(2),
      });
    });
    const transaction = mapCosts({
      reference: 'cart',
      cart: updatedCart,
      currency: updatedCart?.sum?.currencyCode || '',
    });
    const totalsPaypal = {
      total: (transaction.total.centAmount / 100).toFixed(2),
      currency: updatedCart?.sum?.currencyCode,
      details: {
        subtotal: (transaction.subtotal.centAmount / 100).toFixed(2),
        tax: (transaction.tax.centAmount / 100).toFixed(2),
        discount: (transaction.discount.centAmount / 100).toFixed(2),
      },
    };

    return {
      totalsPaypal,
      itemsPaypal,
    };
  };

  let paypalPayment: any = null;
  const submitPaypalDetails = async () => {
    setIsLoading(true);
    // Create payment and add to cart
    await createPaymentForPaypal(
      paypalPayment.transactions[0].related_resources[0].sale.id,
      paypalPayment?.payer?.payer_info?.payer_id,
    );

    // Create order
    const token = window.localStorage.getItem('idToken');
    const user = jwtDecode<SSOToken>(token || '');
    const affiliateId = user['custom:affiliate_id'];
    const diveShopLocalStorage = getLocalStorage('affiliateDiveShop');
    const storeNumber = diveShopLocalStorage || '';
    const country = countries.find((c) => c.twoLetterISORegionName === cart?.billingAddress?.country);
    let shippingAddress = undefined;
    if (hasShippableItemRef.current) {
      const address = paypalPayment?.payer?.payer_info.shipping_address;
      shippingAddress = {
        ...(addressFormDataRef.current || cart?.billingAddress),
        city: address.city,
        country: address.country_code,
        streetName: address.line1,
        streetNumber: address.line2,
        postalCode: address.postal_code,
        state: address.state,
        firstName: address.recipient_name,
        lastName: '',
      } as AccountAddress;
    }
    let billingAddress = {
      ...addressFormDataRef.current,
      firstName: paypalPayment?.payer?.payer_info?.first_name,
      lastName: paypalPayment?.payer?.payer_info?.last_name,
    };
    const payerAddress = paypalPayment?.payer?.payer_info?.billing_address;
    if (payerAddress) {
      billingAddress = {
        ...billingAddress,
        streetName: payerAddress.line1,
        streetNumber: payerAddress.line2,
        postalCode: payerAddress.postal_code,
        city: payerAddress.city,
        state: payerAddress.state,
        country: payerAddress.country_code,
      };
    }
    datadogLogs.logger.info('Checkout page - Create Paypal order', {
      data: {
        email: account?.email,
        cart,
        paypalInfo: paypalPayment,
        shippingAddress,
        billingAddress,
      },
    });
    // const result = await createOrderForPaypal(affiliateId, storeNumber, country, billingAddress, shippingAddress);
    const result = await createOrderForPaypal(affiliateId, storeNumber, country, cart?.billingAddress, shippingAddress);
    if (result) {
      router.push(`/thank-you?orderNumber=${cart?.cartId}`);
    } else {
      setIsLoading(false);
      toast.error(
        `${formatCheckoutMessage({
          id: 'payment.failed',
          defaultMessage: 'We could not process your payment, please try again later.',
        })}`,
        { position: 'top-center' },
      );
    }
  };

  const renderPaypalButton = (clientId: string) => {
    /**
     * In datadog report: sometimes, the browser render takes a long time as expected.
     * The renderPaypalButton was called without paypal-button in dom.
     */
    const elementIsReady = document.getElementById('paypal-button');
    if (!(window as any).paypal || !elementIsReady || !clientId) {
      // retryTimeout if the paypal and element are not ready.
      if (!retryTimeout) {
        retryTimeout = setTimeout(() => renderPaypalButton(clientId), 200);
      }
      return;
    }

    const paypalEnv: string = process.env.NEXT_PUBLIC_PAYPAL_ENV || 'sandbox';
    // @ts-ignore
    if (window.paypal && window.paypal.Button) {
      // @ts-ignore
      window.paypal.Button.render(
        {
          env: paypalEnv,
          client: { [paypalEnv]: clientId },
          commit: true,
          style: {
            label: 'checkout',
            size: 'responsive',
            color: 'blue',
            shape: 'rect',
            tagline: 'false',
          },
          payment: async function (data: any, actions: any) {
            if (!addressFormDataRef?.current?.country || addressFormDataRef.current.country === '') {
              toast.error(
                `${formatCheckoutMessage({
                  id: 'payment.error',
                  defaultMessage: 'Sorry we encountered an error - please try again.',
                })}`,
                { position: 'top-center' },
              );
              datadogLogs.logger.error(
                'Checkout page - Redirecting to cart because of no billing country before Paypal payment',
                {
                  data: {
                    email: account?.email,
                    cart,
                    isLocaleChanged,
                    userCountry: getLocalStorage('padiUserCountry'),
                    addressFormData: addressFormDataRef.current,
                  },
                },
              );
              router.push('/cart');
            } else {
              const { totalsPaypal, itemsPaypal } = await generatePaypalBill();
              const token = window.localStorage.getItem('idToken');
              const user = jwtDecode<SSOToken>(token || '');
              const affiliateId = user['custom:affiliate_id'];
              return actions.payment.create({
                transactions: [
                  {
                    amount: totalsPaypal,
                    item_list: {
                      items: itemsPaypal,
                    },
                    custom: `E${affiliateId}-${account?.firstName}${account?.lastName}`,
                  },
                ],
                application_context: {
                  shipping_preference: hasShippableItemRef.current ? 'GET_FROM_FILE' : 'NO_SHIPPING',
                },
              });
            }
          },
          onAuthorize: function (data: any, actions: any) {
            return actions.payment.execute().then((payment: any) => {
              if (payment.error === 'INSTRUMENT_DECLINED') {
                return actions.payment.restart();
              } else {
                paypalPayment = payment;
                submitPaypalDetails();
              }
            });
          },
        },
        '#paypal-button',
      );
    }
  };

  const hasAddressBeforePayment = () => {
    if (!cart?.billingAddress?.country || !cart?.lineItems || cart.lineItems.length === 0) {
      const userCountry = getLocalStorage('padiUserCountry');
      if (!cart?.billingAddress?.country) {
        toast.error(
          `${formatCheckoutMessage({
            id: 'payment.error',
            defaultMessage: 'Sorry we encountered an error - please try again.',
          })}`,
          { position: 'top-center' },
        );
        datadogLogs.logger.error('Checkout page - Redirecting to cart because of no billing country before payment', {
          data: {
            email: account?.email,
            cart,
            isLocaleChanged,
            userCountry,
            addressFormData,
          },
        });
        router.push('/cart');
      } else {
        datadogLogs.logger.error('Checkout page - Redirecting to courses because of no item', {
          data: {
            email: account?.email,
            cart,
            isLocaleChanged,
            userCountry,
          },
        });
        router.push('/courses');
      }
      return false;
    }
    return true;
  };

  const createStripeSession = useCallback(async () => {
    datadogLogs.logger.info('Checkout page - Create Stripe session', {
      data: {
        email: account?.email,
        cart,
        addressFormData,
        isLocaleChanged,
        userCountry: getLocalStorage('padiUserCountry'),
        userLocale: getLocalStorage('padiUserLocale'),
        locale,
      },
    });
    if (hasAddressBeforePayment()) {
      setProcessing(true);
      // Track the click event.
      trackClick(cart ?? null);

      const url = await createStripeCheckoutSession();

      // Redirect to Stripe checkout page for payment
      if (url) {
        router.push(url);
      } else {
        setProcessing(false);
        toast.error(
          `${formatCheckoutMessage({
            id: 'payment.error',
            defaultMessage: 'Sorry we encountered an error - please try again.',
          })}`,
          { position: 'top-center' },
        );
      }
    }
  }, [addressFormData, cart]);

  const onConfirm = useCallback(async () => {
    setIsLoading(true);
    datadogLogs.logger.info('Checkout page - Before confirm', {
      data: {
        email: account?.email,
        cart,
        addressFormData,
        isLocaleChanged,
        userCountry: getLocalStorage('padiUserCountry'),
        userLocale: getLocalStorage('padiUserLocale'),
        locale,
      },
    });
    const isValidCountry = await validateCountry(addressFormData.country || '');
    if (!isValidCountry) {
      setIsConfirmed(false);
      return;
    }
    let updatedLocale = 'us';
    let isUnmatchedLocale = false;
    const userLocale = getLocalStorage('padiUserLocale');
    const userLanguage = userLocale.split('-')[0];
    const userCountry = getLocalStorage('padiUserCountry')?.toUpperCase();

    // Temporarily check if url locale, local locale and local country are unmatched to prevent unmatched cart currency
    if (userLocale !== locale) {
      isUnmatchedLocale = true;
      toast.error(
        `${formatCheckoutMessage({
          id: 'payment.error',
          defaultMessage: 'Sorry we encountered an error - please try again.',
        })}`,
        { position: 'top-center' },
      );
      await sdk.callAction({ actionName: 'cart/deleteCart' });
      const res = await mutate('/action/cart/getCart');
      datadogLogs.logger.error('Checkout page - Delete cart - Not match locale', {
        data: {
          email: account?.email,
          cart,
          error: res.isError,
          response: res.isError ? res : res.data,
          addressFormData,
          userLocale,
          userLanguage,
          userCountry,
          locale,
        },
      });
      router.push(`/${userLocale}/courses`);
    } else {
      const userCountryField = countries.find((c) => c.twoLetterISORegionName === userCountry);
      const userCountryZone = await getZoneKeyByCountryCode(
        userCountryField?.mappingCode ? userCountryField.mappingCode : userCountry,
      );
      const userCountryLocaleObject = getLocaleByZone(userLanguage, userCountryZone);
      const userCountryLocale = userCountryLocaleObject?.locale;
      if (userCountryLocale !== userLocale || userCountryLocale !== locale) {
        isUnmatchedLocale = true;
        toast.error(
          `${formatCheckoutMessage({
            id: 'payment.error',
            defaultMessage: 'Sorry we encountered an error - please try again.',
          })}`,
          { position: 'top-center' },
        );
        await sdk.callAction({ actionName: 'cart/deleteCart' });
        const res = await mutate('/action/cart/getCart');
        datadogLogs.logger.error('Checkout page - Delete cart - Not match user locale', {
          data: {
            email: account?.email,
            cart,
            error: res.isError,
            response: res.isError ? res : res.data,
            addressFormData,
            userLocale,
            userCountryLocale,
            userLanguage,
            userCountry,
            locale,
          },
        });
        setLocalStorage('padiUserLocale', userCountryLocale);
        setLocalStorage('padiUserZone', userCountryZone);
        router.push(`/${userCountryLocale}/courses`);
      }
    }
    if (isUnmatchedLocale) {
      return;
    }

    if (isLocaleChanged) {
      await sdk.callAction({ actionName: 'cart/deleteCart' });
      const res = await mutate('/action/cart/getCart');
      const country = countries.find((c) => c.twoLetterISORegionName === addressFormData.country);
      const updatedZone = await getZoneKeyByCountryCode(
        country?.mappingCode ? country.mappingCode : addressFormData.country || '',
      );
      const updatedLocaleObject = getLocaleByZone(userLanguage, updatedZone);
      updatedLocale = updatedLocaleObject?.locale;
      datadogLogs.logger.info('Checkout page - Delete cart', {
        data: {
          email: account?.email,
          cart,
          error: res.isError,
          response: res.isError ? res : res.data,
          addressFormData,
          userLocale,
          updatedLocale,
          updatedZone,
          userLanguage,
          userCountry,
          locale,
        },
      });
      setLocalStorage('padiUserLocale', updatedLocale);
      setLocalStorage('padiUserZone', updatedZone);
      setLocalStorage('padiUserCountry', addressFormData.country?.toLowerCase() || '');
      router.push(`/${updatedLocale}/courses`);
    } else {
      if (!account?.externalId) {
        const token = window.localStorage.getItem('idToken');
        const user = jwtDecode<SSOToken>(token || '');
        const affiliateId = user['custom:affiliate_id'];
        await sdk.callAction({
          actionName: 'account/updateExternalId',
          payload: {
            affiliateId,
          },
        });
      }

      const address = await updateAddress(addressFormData);
      if (address) {
        addressFormDataRef.current = address;
      } else {
        addressFormDataRef.current = addressFormData;
      }
      if (cart?.lineItems && cart.lineItems.length > 0) {
        const res = await mutate('/action/cart/getCart');
        datadogLogs.logger.info('Checkout page - Confirmed', {
          data: {
            email: account?.email,
            cart,
            isError: res.isError,
            response: res.isError ? res : res.data,
            addressFormData,
            updatedAddress: address,
          },
        });
      }
      setIsConfirmed(true);
      setIsLoading(false);
    }
  }, [addressFormData, cart]);

  const validateCountry = async (countryCode: string): Promise<boolean> => {
    let hasNonsellableCertCard = false;
    let hasShippableItem = false;
    let hasNonsellableItem = false;
    const country = countries.find((c) => c.twoLetterISORegionName === countryCode);
    const shippableCategory = await getCategoryIdBySlug('shippable');
    const certCardCategory = await getCategoryIdBySlug('certification-card');
    const nonSellableCategory = await getCategoryIdBySlug(
      `not-purchasable-${country?.mappingCode ? country.mappingCode.toLowerCase() : countryCode.toLowerCase()}`,
    );
    if (cart?.lineItems && cart.lineItems.length > 0) {
      for (let i = 0; i < cart?.lineItems?.length; i++) {
        const item = cart?.lineItems[i];
        let isNonsellable = false;
        let isCertCard = false;
        let isShippable = false;
        const product = await getProduct(item.productId);
        product?.categories?.forEach((c: Category) => {
          if (c.categoryId === certCardCategory.categoryId) {
            isCertCard = true;
          } else if (c.categoryId === nonSellableCategory?.categoryId) {
            isNonsellable = true;
          } else if (c.categoryId === shippableCategory.categoryId) {
            isShippable = true;
          }
        });
        if (isNonsellable) {
          if (isCertCard) {
            hasNonsellableCertCard = true;
            setNonsellableMessage(
              formatCheckoutMessage({
                id: 'not.sell.in.local.office',
                defaultMessage:
                  'Sorry, we do not sell the product you’ve added in the selected country. Contact your local PADI Office to purchase Cards.',
              }),
            );
            break;
          } else if (!hasNonsellableItem) {
            hasNonsellableItem = true;
          }
        }
        if (isShippable && !hasShippableItem) {
          hasShippableItem = true;
          setHasShippableItem(true);
          hasShippableItemRef.current = hasShippableItem;
        }
      }
    }
    if (hasNonsellableItem) {
      setNonsellableMessage(
        formatCheckoutMessage({
          id: 'not.sell.in.country',
          defaultMessage: 'Sorry, we do not sell the product you’ve added in the selected country.',
        }),
      );
    }
    const isValidCountry = !hasNonsellableCertCard && !hasNonsellableItem;
    if (isValidCountry) {
      setNonsellableMessage('');
    }
    datadogLogs.logger.info('Checkout page - Validate country', {
      data: {
        email: account?.email,
        cart,
        addressFormData,
        countryCode,
        isValidCountry,
        hasShippableItem,
        hasNonsellableCertCard,
        hasNonsellableItem,
      },
    });
    return isValidCountry ? true : false;
  };

  const onVerifiedSummaryLocale = () => {
    setIsLoadingOrder(false);
  };

  const [isDesktopSize] = useMediaQuery(desktop);

  return (
    <div className="mx-auto min-h-screen max-w-7xl px-16 py-40 lg:px-32 lg:py-48">
      <style>
        {`
        .disable {
          pointer-events: none;
          opacity: 0.4;
        }
        `}
      </style>
      <Secure className="!lg:px-0 !md:px-0 !bg-transparent !px-0" />
      <div className="flex-row items-start justify-between gap-24 lg:flex">
        <div className="flex flex-col gap-24 px-0 md:max-w-[700px] lg:grow">
          {isAlreadyClubMember && !isLoadingPaypal && (
            <Alert
              alertType="warning"
              body={`${formatMessagePadi({
                id: 'subscription.cart.alert',
                defaultMessage:
                  'Our records show you already have a PADI Club Membership. Access your benefits and manage your subscription on the Club Benefits page.',
              })}`}
            />
          )}
          {!isValidating && hasShippableItem && cart?.sum?.currencyCode === 'USD' && (
            <p className="text-16 font-bold">
              {formatCheckoutMessage({ id: 'shippingAddress', defaultMessage: 'Shipping Address' })}
            </p>
          )}
          {!isValidating && (!hasShippableItem || cart?.sum?.currencyCode !== 'USD') && (
            <p className="text-16 font-bold">
              {formatCheckoutMessage({ id: 'billingLocation', defaultMessage: 'Billing Location' })}
            </p>
          )}
          {nonsellableMessage && <Alert alertType="error" body={nonsellableMessage} />}
          {!isConfirmed && isLocaleChanged && !nonsellableMessage && (
            <Alert
              alertType="warning"
              label={`${formatMessagePadi({
                id: 'attention',
                defaultMessage: 'Attention',
              })}`}
              body={`${formatMessagePadi({
                id: 'change.country.alert',
                defaultMessage:
                  'Changing your billing country will change your currency and price. Continuing will remove any items in your cart.',
              })}`}
            />
          )}
          <Dropdown
            name="country"
            items={countries.map((c) => {
              return {
                label: c.englishName || '',
                value: c.twoLetterISORegionName,
              };
            })}
            className="w-full border-neutral-500"
            onChange={handleCountryChange}
            value={addressFormData.country}
            disabled={isAlreadyClubMember}
            label={formatMessage({ id: 'country', defaultMessage: 'Country' })}
          />
          {states && states.length > 0 && (
            <Dropdown
              name="state"
              items={states.map((c) => {
                return {
                  label: c.name || '',
                  value: c.code,
                };
              })}
              className="w-full border-neutral-500"
              onChange={handleStateChange}
              value={addressFormData.state}
              label={formatMessage({ id: 'stateOrProvince', defaultMessage: 'State / Region / Province' })}
            />
          )}
          <div>
            <Button
              variant="primary"
              className="mt-24 py-15 lg:mt-40"
              type="submit"
              disabled={isConfirmed || isAlreadyClubMember || nonsellableMessage !== ''}
              onClick={onConfirm}
              loading={isLoading || isLoadingPaypal || isLoadingOrder || isValidating}
              dataAnalytics={PURCHASE}
            >
              {formatMessage({ id: 'confirm', defaultMessage: 'Confirm' })}
            </Button>
          </div>
        </div>
        <div className="mt-10 bg-white md:pr-0 lg:mt-0 lg:w-[30%] lg:rounded-md lg:py-36 lg:pl-24">
          <div hidden={!isConfirmed || isLoading || firstLoad || isValidating}>
            <OrderSummary
              includeSummaryAccordion
              title={formatCartMessage({ id: 'order.summary', defaultMessage: 'Order summary' })}
              classNames={{
                applyDiscountButton: 'lg:mb-0 py-14 text-16 mb-16 border-b border-neutral-400 lg:border-b-transparent',
                totalAmount: 'text-18 md:pb-20',
                subCostsContainer: 'pt-12 md:pt-16 mb-20 lg:py-24 lg:mb-16 lg:border-b border-neutral-400',
              }}
              includeFeeAndTax={true}
              onVerifiedSummaryLocale={onVerifiedSummaryLocale}
              button={
                <Button
                  variant="primary"
                  className="mb-16 w-full py-15"
                  type="submit"
                  onClick={createStripeSession}
                  loading={processing}
                  dataAnalytics={PURCHASE}
                >
                  {formatCheckoutMessage({ id: 'continue.to.payment', defaultMessage: 'Continue to payment' })}
                </Button>
              }
            />
          </div>
          {isDesktopSize && !isSubscriptionInCart && (
            <div
              className="z-0 w-full"
              style={{
                position: 'relative',
              }}
              id="paypal-button"
              hidden={isValidating || !isConfirmed || isLoading || isLoadingOrder}
            ></div>
          )}
          {!isDesktopSize && (
            <div className="m-auto max-w-[600px]">
              <Button
                variant="primary"
                className="mb-16 w-full py-15"
                type="submit"
                disabled={isLoading}
                onClick={createStripeSession}
                loading={processing}
                dataAnalytics={PURCHASE}
                hidden={!isConfirmed || isLoading || firstLoad || isLoadingOrder || isValidating}
              >
                {formatCheckoutMessage({ id: 'continue.to.payment', defaultMessage: 'Continue to payment' })}
              </Button>
              {!isSubscriptionInCart && (
                <div
                  className="z-0 w-full"
                  style={{
                    position: 'relative',
                  }}
                  id="paypal-button"
                  hidden={isValidating || !isConfirmed || isLoading || firstLoad || isLoadingOrder}
                ></div>
              )}
            </div>
          )}
          {!isValidating && isConfirmed && !isLoading && !firstLoad && !isLoadingOrder && <PaymentMethods />}
        </div>
      </div>
    </div>
  );
};

export default AddressForm;
